import {
  getWithAuth,
  getWithOutAuth,
  postWithAuth,
  postWithOutAuth,
  putWithAuth,
  putWithOutAuth,
} from 'services/http.service';
import {
  AUTHENTICATE_USER,
  CREATE_USER,
  CREATE_VENDOR_ONBOARDING_DATA,
  GET_USER_DETAILS,
  GET_USER_ONBOARDING_API,
  RESEND_OTP,
  SIGNIN_USER_BY_EMAIL,
  SIGNUP_USER_BY_EMAIL,
  USER_ONBOARDING_API,
  VERIFY_OTP,
  GENERATE_COMPANY_LOGO_URL,
  GET_COMPANY_LOGO_URL,
  UPDATE_COMPANY_LOGO,
  GET_BUSINESS_DETAILS,
  GET_BUSINESS_DETAILS_BY_EMAIL,
  GET_VENDOR_BUSINESS_DETAILS_BY_SUPPLIER_ID,
  CREATE_API_AUTH_TOKEN,
  VERIFY_API_AUTH_TOKEN,
  CREATE_CHECKOUT_SESSION,
  GET_PAYMENT_METHODS,
  GET_INVOICES,
  GET_ACTIVE_SUBSCRIPTION,
  CREATE_SETUP_INTENT,
  GET_SETUP_INTENT_STATUS,
  REMOVE_PAYMENT_METHOD,
  UPDATE_DEFAULT_PAYMENT_METHOD,
  CANCEL_SUBSCRIPTION,
  GET_CHECKOUT_SESSION_STATUS,
  AUTO_RENEWAL_TOGGLE,
  GET_ACTIVE_PLAN,
  TOGGLE_FREE_ACCESS_BY_ADMIN,
} from './url.service';

export const createUser = (user) => postWithOutAuth(CREATE_USER, user);
export const createVendorOnboardingData = (data) =>
  postWithOutAuth(CREATE_VENDOR_ONBOARDING_DATA, data);
export const authenticateUser = (email) =>
  getWithOutAuth(AUTHENTICATE_USER(email));
export const signUpUserByEmail = (user) =>
  postWithOutAuth(SIGNUP_USER_BY_EMAIL, user);
export const signInUserByEmail = (email) =>
  postWithOutAuth(SIGNIN_USER_BY_EMAIL, email);
export const verifyUserOtp = (data) => postWithOutAuth(VERIFY_OTP, data);
export const resendUserOtp = (email) => postWithOutAuth(RESEND_OTP, email);
export const createUserOnboardingData = (onBoardingData) =>
  postWithAuth(USER_ONBOARDING_API, onBoardingData);
export const toggleFreeAccessByAdmin = (onBoardingData) =>
  postWithAuth(TOGGLE_FREE_ACCESS_BY_ADMIN, onBoardingData);
export const updateUserOnboardingData = (onBoardingData) =>
  putWithAuth(USER_ONBOARDING_API, onBoardingData);
export const getUserOnboardingData = (userId) =>
  getWithAuth(GET_USER_ONBOARDING_API(userId));
export const getUserDetails = (userId) => getWithAuth(GET_USER_DETAILS(userId));
export const getBusinessDetails = (businessId) =>
  getWithAuth(GET_BUSINESS_DETAILS(businessId));
export const getBusinessDetailsByEmail = (businessEmail) =>
  getWithAuth(GET_BUSINESS_DETAILS_BY_EMAIL(businessEmail));
export const getVendorBusinessDetailsBySupplierID = (supplierId) =>
  getWithAuth(GET_VENDOR_BUSINESS_DETAILS_BY_SUPPLIER_ID(supplierId));
export const generateCompanyLogoUrl = (email) =>
  getWithAuth(GENERATE_COMPANY_LOGO_URL(email));
export const uploadCompanyLogoUrl = (url, file) => putWithOutAuth(url, file);
export const getCompanyLogoUrl = (fileUrl = 'uploaded') =>
  getWithAuth(GET_COMPANY_LOGO_URL(fileUrl));
export const updateCompanyLogo = (logoDetails) =>
  putWithAuth(UPDATE_COMPANY_LOGO, logoDetails);

export const createAPIAuthToken = () => getWithAuth(CREATE_API_AUTH_TOKEN);
export const verifyAPIAuthToken = (data) =>
  postWithOutAuth(VERIFY_API_AUTH_TOKEN, data);

//Stripe
export const createStripeCheckoutSession = (data) =>
  postWithAuth(CREATE_CHECKOUT_SESSION, data);

export const getPaymentMethods = (userId) =>
  getWithAuth(`${GET_PAYMENT_METHODS}?id=${userId}`);

export const getInvoices = (userId, limit = 5, startingAfter = null) =>
  getWithAuth(
    `${GET_INVOICES}?id=${userId}&limit=${limit}${
      startingAfter ? `&startingAfter=${startingAfter}` : ''
    }`
  );
export const createSetupIntent = (userId) =>
  postWithAuth(CREATE_SETUP_INTENT, { id: userId });

export const getSetupIntentStatus = (userId, setupIntentClientSecret) =>
  getWithAuth(
    `${GET_SETUP_INTENT_STATUS}?id=${userId}&setupIntentClientSecret=${setupIntentClientSecret}`
  );
export const getCheckSessionStatus = (sessionId) =>
  getWithAuth(`${GET_CHECKOUT_SESSION_STATUS}?sessionId=${sessionId}`);

export const removePaymentMethod = (userId, paymentId, subscriptionId) =>
  postWithAuth(REMOVE_PAYMENT_METHOD, {
    id: userId,
    paymentId,
    subscriptionId,
  });
export const autoRenewToggle = (subscriptionId, autoRenew) =>
  postWithAuth(AUTO_RENEWAL_TOGGLE, { subscriptionId, autoRenew });

export const updateDefaultPaymentMethod = (userId, paymentId) =>
  postWithAuth(UPDATE_DEFAULT_PAYMENT_METHOD, {
    id: userId,
    paymentId,
  });
export const getActivePlans = () => getWithAuth(GET_ACTIVE_PLAN);

export const cancelSubscription = (userId) =>
  postWithAuth(CANCEL_SUBSCRIPTION, { id: userId });

export const getActiveSubscription = (userId) =>
  getWithAuth(`${GET_ACTIVE_SUBSCRIPTION}?id=${userId}`);

// check if in use
export const getUsers = () => getWithOutAuth(`${URL}/users`);
export const getUser = (userId) =>
  getWithOutAuth(`http://localhost:3000/users/${userId}`);
