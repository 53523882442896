import PropTypes from 'prop-types';
import { useRef } from 'react';
import IcomoonIcon from './IcomoonIcon';
import Image from 'next/image';
const Button = ({
  onClick = () => {},
  id,
  varient = 'mediumContained',
  fullWidth = false,
  disabled = false,
  btnClass = '',
  children,
  flex,
  showIcon,
  size = 10,
  justify,
  icon = 'angle-right',
  isLoading = false,
  classForLoader = '',
  ...Properties
}) => {
  const buttonRef = useRef(null);

  const createRippleEffect = (event) => {
    const button = buttonRef.current;
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;
    const buttonRect = button.getBoundingClientRect();
    const x = event.clientX - buttonRect.left - radius;
    const y = event.clientY - buttonRect.top - radius;
    const ripple = document.createElement('span');
    ripple.style.width = ripple.style.height = `${diameter}px`;
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;
    ripple.classList.add(
      'absolute',
      'rounded-full',
      'bg-white',
      'animate-ripple'
    );
    button.appendChild(ripple);
    setTimeout(() => {
      ripple.remove();
    }, 500);
  };

  const buttonType = {
    mediumContained: 'mediumContained',
    mediumContainedAlternate: 'mediumContainedAlternate',
    ghost: 'ghost',
    largeTransparent: 'largeTransparent',
    largeTransparentAlternate: 'largeTransparentAlternate',
  };

  const buttonStyle = {
    mediumContained:
      'bg-primary-900 rounded shadow-sm text-white p-1 xl:p-2.5 2xl:p-3 text-xs xl:text-sm 2xl:text-base font-medium items-center leading-6 transition duration-300 hover:bg-primary-800 whitespace-pre cursor-pointer disabled:bg-blue-100  disabled:cursor-not-allowed',
    mediumContainedAlternate:
      'bg-primary-900 py-1.5 pl-1 pr-3 2xl:py-2.5 2xl:pl-1.5 2xl:pr-[17px] text-white font-medium text-xs xl:text-sm 2xl:text-base items-center transition duration-300 hover:bg-primary-800 rounded whitespace-pre cursor-pointer disabled:bg-blue-100  disabled:cursor-not-allowed',
    mediumAlternate:
      'bg-primary-900 rounded shadow-sm text-white py-1 px-10 xl:py-3 xl:px-12 text-xs xl:text-sm 2xl:text-base font-medium items-center leading-6 transition duration-300 hover:bg-primary-800 whitespace-pre disabled:bg-blue-100 cursor-pointer disabled:cursor-not-allowed',
    mediumAlternateWithIcon:
      'bg-primary-900 rounded shadow-sm text-white py-1 px-5 xl:py-3 xl:px-[25px] text-xs xl:text-sm 2xl:text-base font-medium items-center leading-6 transition duration-300 hover:bg-primary-800 whitespace-pre cursor-pointer disabled:bg-blue-100  disabled:cursor-not-allowed',

    ghost:
      'bg-primary-700 rounded text-primary-900 font-medium text-xs xl:text-sm 2xl:text-base items-center whitespace-pre py-2.5 xl:py-3 px-4 cursor-pointer disabled:bg-blue-100 disabled:cursor-not-allowed',
    ghostContained:
      'bg-primary-700 rounded text-primary-900 font-medium text-xs xl:text-sm 2xl:text-base items-center whitespace-pre px-[18px] py-1 xl:py-3 xl:px-[22px] cursor-pointer disabled:bg-blue-100 disabled:cursor-not-allowed',
    ghostAlternate:
      'bg-primary-700 rounded text-primary-900 font-medium text-xs xl:text-sm 2xl:text-base items-center whitespace-pre px-10 py-1 xl:py-3 xl:px-12 cursor-pointer disabled:bg-[#E8EEF3] disabled:opacity-50 disabled:cursor-not-allowed',

    largeContained:
      'bg-primary-900 pl-1 pr-4 py-1.5 2xl:py-2.5 2xl:pl-1.5 2xl:pr-6 text-white text-xs xl:text-sm 2xl:text-base font-medium items-center transition duration-300 hover:bg-primary-800 rounded whitespace-pre cursor-pointer disabled:bg-blue-100  disabled:cursor-not-allowed',
    largeContainedAlternate:
      'bg-primary-900 text-white font-medium text-xs xl:text-sm 2xl:text-base py-3 px-6 2xl:py-4 2xl:px-[38px] cursor-pointer rounded whitespace-pre items-center hover:bg-primary-800 transition duration-300',
    transparent:
      'text-primary-900 font-medium text-xs xl:text-sm whitespace-pre cursor-pointer',
    largeTransparent: `bg-transparent text-white border border-white hover:bg-white hover:text-primary-900 rounded p-1 xl:p-2.5 2xl:p-3 text-xs xl:text-sm 2xl:text-base font-medium items-center leading-6 transition duration-300 whitespace-pre cursor-pointer disabled:bg-blue-100  disabled:cursor-not-allowed`,
    largeTransparentAlternate: `bg-transparent text-primary-900 border border-primary-900 hover:text-white hover:bg-primary-900 rounded p-1 xl:p-2.5 2xl:p-3 text-xs xl:text-sm 2xl:text-base font-medium items-center leading-6 transition duration-300 whitespace-pre cursor-pointer disabled:bg-blue-100  disabled:cursor-not-allowed`,
  };

  return (
    <button
      id={id}
      ref={buttonRef}
      varient={buttonType[varient]}
      className={`relative overflow-hidden ${
        buttonStyle[varient]
      } ${flex} ${justify} ${btnClass} ${fullWidth && 'w-full'}`}
      disabled={disabled}
      onClick={(e) => {
        createRippleEffect(e), onClick(e);
      }}
      {...Properties}
    >
      {isLoading && (
        <div className={`animate-spin w-5 h-5 relative ${classForLoader}`}>
          <Image src="/images/loader.svg" alt="loader" layout="fill" />
        </div>
      )}
      {children}
      {showIcon && <IcomoonIcon icon={icon} size={size} color="#fff" />}
    </button>
  );
};

Button.propTypes = {
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  btnClass: PropTypes.string,
  showIcon: PropTypes.bool,
  flex: PropTypes.string,
  justify: PropTypes.string,
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  classForLoader: PropTypes.string,
};

export default Button;
