const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

const UrlParamsReplace = (url, params = {}) => {
  let urlWithPrefix = `${ApiUrl}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  return urlWithPrefix;
};

// Users
export const CREATE_USER = UrlParamsReplace('/user/create');
export const CREATE_VENDOR_ONBOARDING_DATA =
  UrlParamsReplace('/vendor/onboarding');

export const AUTHENTICATE_USER = (email) =>
  UrlParamsReplace('/user-authentication/:email', { email });
export const SIGNUP_USER_BY_EMAIL = UrlParamsReplace('/user/signUp');
export const SIGNIN_USER_BY_EMAIL = UrlParamsReplace('/user/signIn');
export const USER_ONBOARDING_API = UrlParamsReplace('/onboarding');
export const TOGGLE_FREE_ACCESS_BY_ADMIN = UrlParamsReplace(
  '/toggle-free-access-by-admin'
);
export const GET_USER_ONBOARDING_API = (userId) =>
  UrlParamsReplace('/onboarding?userId=:userId', { userId });
export const GET_USER_DETAILS = (userId) =>
  UrlParamsReplace('/user?userId=:userId', { userId });
export const GET_BUSINESS_DETAILS = (businessId) =>
  UrlParamsReplace('/business?businessId=:businessId', { businessId });
export const GET_BUSINESS_DETAILS_BY_EMAIL = (businessEmail) =>
  UrlParamsReplace('/business/email?businessEmail=:businessEmail', {
    businessEmail,
  });

export const GET_VENDOR_BUSINESS_DETAILS_BY_SUPPLIER_ID = (supplierId) =>
  UrlParamsReplace('/vendorBusiness?supplierId=:supplierId', {
    supplierId,
  });

export const CREATE_API_AUTH_TOKEN = UrlParamsReplace(
  '/user/create-api-auth-token'
);
export const VERIFY_API_AUTH_TOKEN = UrlParamsReplace('/verify-api-auth-token');

// User_Otps
export const VERIFY_OTP = UrlParamsReplace('/user_otps/verify-otp');
export const RESEND_OTP = UrlParamsReplace('/user_otps/resend-otp');

// Stripe
export const CREATE_CHECKOUT_SESSION = UrlParamsReplace(
  '/stripe/create-checkout-session'
);
export const GET_PAYMENT_METHODS = UrlParamsReplace('/stripe/payment-methods');

export const GET_INVOICES = UrlParamsReplace('/stripe/invoices');

export const GET_ACTIVE_SUBSCRIPTION = UrlParamsReplace(
  '/stripe/active-subscription'
);
export const CREATE_SETUP_INTENT = UrlParamsReplace('/stripe/setup-intent');

export const GET_SETUP_INTENT_STATUS = UrlParamsReplace(
  '/stripe/setup-intent-status'
);

export const REMOVE_PAYMENT_METHOD = UrlParamsReplace(
  '/stripe/remove-payment-method'
);

export const UPDATE_DEFAULT_PAYMENT_METHOD = UrlParamsReplace(
  '/stripe/update-default-payment-method'
);

export const CANCEL_SUBSCRIPTION = UrlParamsReplace(
  '/stripe/cancel-subscription'
);
export const GET_CHECKOUT_SESSION_STATUS = UrlParamsReplace(
  '/stripe/check-active-status'
);
export const AUTO_RENEWAL_TOGGLE = UrlParamsReplace(
  '/stripe/auto-renewal-toggle'
);
export const GET_ACTIVE_PLAN = UrlParamsReplace('/stripe/plans');

// Projects
export const CREATE_PROJECT = UrlParamsReplace('/projects');
export const GET_USER_PROJECTS = (userId, searchText, page) =>
  UrlParamsReplace(
    '/projects?userId=:userId&searchText=:searchText&page=:page',
    {
      userId,
      searchText,
      page,
    }
  );
export const GET_PROJECT_DETAILS = (projectId) =>
  UrlParamsReplace('/projects/:projectId', { projectId });
export const EDIT_PROJECT_DETAILS = (projectId) =>
  UrlParamsReplace('/projects/:projectId', { projectId });
export const DELETE_PROJECT_DETAILS = (projectId) =>
  UrlParamsReplace('/projects/:projectId', { projectId });
export const UPDATE_PROJECT_LAST_VISITED = (projectId) =>
  UrlParamsReplace('/projects/:projectId/last-visited', { projectId });

// Folders
export const GET_PROJECT_FOLDERS = (projectId, searchText, page) =>
  UrlParamsReplace(
    '/projects/:projectId/folders?searchText=:searchText&page=:page',
    {
      projectId,
      searchText,
      page,
    }
  );
export const CREATE_PROJECT_FOLDERS = UrlParamsReplace('/folders');
export const GENERATE_PRESIGNED_URL = (projectId, folderId, fileName) =>
  UrlParamsReplace('/generate-presigned-url/:projectId/:folderId/:fileName', {
    projectId,
    folderId,
    fileName,
  });

export const GET_PRESIGNED_URL = (fileUrl, type) =>
  UrlParamsReplace('/get-presigned-url?fileUrl=:fileUrl&type=:type', {
    fileUrl,
    type,
  });

export const GET_FOLDER_DETAILS = (folderId) =>
  UrlParamsReplace('/folders/:folderId', { folderId });
export const GET_USER_FOLDER = (userId) =>
  UrlParamsReplace(`/folders?userId=${userId}`);
export const UPDATE_FOLDER_LAST_VISITED = (folderId) =>
  UrlParamsReplace('/folders/:folderId/last-visited', { folderId });
export const DELETE_FOLDERS = (folderId) =>
  UrlParamsReplace('/folders/:folderId', { folderId });
export const EDIT_FOLDER_DETAILS = (folderId) =>
  UrlParamsReplace('/folders/:folderId', { folderId });

// Document Types
export const GET_FOLDER_DOC_TYPES = UrlParamsReplace('/document-types');

// Files
export const CREATE_FILES = UrlParamsReplace('/files');
export const GET_FILES_IN_FOLDER = (folderId, searchText, page) =>
  UrlParamsReplace('/files/:folderId?searchText=:searchText&page=:page', {
    folderId,
    searchText,
    page,
  });
export const GET_ALL_FILES = (userId, searchText) =>
  UrlParamsReplace('/files?searchText=:searchText&userId=:userId', {
    searchText,
    userId,
  });
export const DELETE_FILE = (fileId) =>
  UrlParamsReplace('/files/:fileId', { fileId });
export const DELETE_MULTIPLE_FILES = UrlParamsReplace('/files');
export const GET_METRICS_FOR_UPLOADED_AND_PROCESSED_FILES = (
  startDate,
  endDate,
  midDate
) =>
  UrlParamsReplace(
    '/files-metrics-for-uploaded-and-processed?startDate=:startDate&endDate=:endDate&midDate=:midDate',
    { startDate, endDate, midDate }
  );
export const GET_METRICS_FOR_PROCESSED_FILES = (startDate, endDate) =>
  UrlParamsReplace(
    '/files-metrics-for-processed?startDate=:startDate&endDate=:endDate',
    { startDate, endDate }
  );
export const CHECK_FOR_EXISTING_FILES = (folderId) =>
  UrlParamsReplace('/files/:folderId/check-exisiting-files', { folderId });
export const GET_FILE_DETAILS_BY_ID = (fileId) =>
  UrlParamsReplace('/file/:fileId', { fileId });

export const UPDATE_FILE_STATUS = (fileId, status) =>
  UrlParamsReplace('/files/:fileId/:status', { fileId, status });

export const EMAIL_PURCHASE_ORDER = UrlParamsReplace('/email-purchase-order');

export const CREATE_FORM_PDF = UrlParamsReplace('/create-form-pdf');

// Analyse Files
export const ANALYSE_FILES = UrlParamsReplace('/document-processing');
export const ANALYSE_FILES_PROGRESS = (name) =>
  UrlParamsReplace('/document-processing-progress?name=:name', { name });
export const ANALYSE_SINGLE_FILE = UrlParamsReplace(
  '/single-document-processing'
);

// Export Files
export const EXPORT_FILES = UrlParamsReplace('/export-files');
export const EXPORT_STATUS_PROGRESS = (exportStatusId) =>
  UrlParamsReplace('/export-progress/:exportStatusId', { exportStatusId });
export const GENERATE_JSON_FOR_CSV = (fileId) =>
  UrlParamsReplace('/generate-json/:fileId', { fileId });

//Company Logo
export const GENERATE_COMPANY_LOGO_URL = (email) =>
  UrlParamsReplace('/generate-company-logo-url/:email', {
    email,
  });
export const GET_COMPANY_LOGO_URL = (fileUrl) =>
  UrlParamsReplace('/get-company-logo-url?fileUrl=:fileUrl', {
    fileUrl,
  });
export const UPDATE_COMPANY_LOGO = UrlParamsReplace('/update-company-logo-api');

// Forms
export const CREATE_PURCHASE_ORDER = UrlParamsReplace('/form/purchase-order');
export const CREATE_RECEIVING_SLIP = UrlParamsReplace('/form/receiving-slip');

export const UPDATE_PURCHASE_ORDER_ITEMS = UrlParamsReplace(
  '/form/update-purchase-order-items'
);

export const UPDATE_PURCHASE_ORDER_FILE = UrlParamsReplace(
  '/form/purchase-order/update-file'
);
export const MAIL_UPDATED_PURCHASE_ORDER_FILE = UrlParamsReplace(
  '/form/purchase-order/mail-updated-file'
);

export const GET_PO_DETAILS_BY_FILE_ID = (fileId) =>
  UrlParamsReplace('/purchaseOrder/:fileId', { fileId });

export const GET_PO_DETAILS_BY_SUPPLIER_ID = (supplierId) =>
  UrlParamsReplace('/purchaseOrder/inventory/:supplierId', { supplierId });

export const GET_RECEIVING_SLIP_DETAILS_BY_PO_ID = (poId) =>
  UrlParamsReplace('/receivingSlip/:poId', { poId });

export const GET_PO_DETAILS_BY_PO_NUMBERS = (poNumbers) =>
  UrlParamsReplace('/purchaseOrder/invoiceMatch/:poNumbers', { poNumbers });

export const GET_MAX_PO_NUMBER = UrlParamsReplace(
  '/purchaseOrder/getMaxNumber'
);
export const GET_MAX_RECEIVING_SLIP = UrlParamsReplace(
  '/purchaseOrder/getMaxReceivingSlip'
);
export const GET_MAX_RETURN_ORDER_NUMBER = UrlParamsReplace(
  '/purchaseOrder/getMaxReturnOrderNumber'
);
export const EDIT_PO_DETAILS = (poId) =>
  UrlParamsReplace('/purchaseOrder/:poId', { poId });

export const GET_USER_PURCHASE_ORDERS = (projectId) =>
  UrlParamsReplace('/purchaseOrder/project/:projectId', { projectId });

export const GET_INVOICE_RECEIVING_SLIP_MATCHING = UrlParamsReplace(
  '/purchaseOrder/invoiceReceivingSlipMatching'
);
export const GET_INVOICE_COUNTS = UrlParamsReplace('/invoiceDashboard/counts');
export const GET_MARKETING_DASHBOARD = (id) =>
  UrlParamsReplace('/dashboard/marketing/:id', { id });

export const SEARCH_CAMPAIGN_MARKETING_DASHBOARD = (id, search) =>
  UrlParamsReplace('/dashboard/marketing/campaign-search/:id/:search', {
    id,
    search,
  });
export const SEARCH_ORDER_DASHBOARD = (id, search) =>
  UrlParamsReplace('/dashboard/orders/search/:id/:search', {
    id,
    search,
  });
export const UPDATE_ORDER_STATUS_DASHBOARD = (id) =>
  UrlParamsReplace('/dashboard/order/:id', {
    id,
  });
export const GET_ORDER_DASHBOARD = (id) =>
  UrlParamsReplace('/order/:id', { id });
export const GET_ORDER_STATS_DASHBOARD = (id) =>
  UrlParamsReplace('/order/dashboard/:id', { id });
export const CREATE_PURCHASE_ORDER_API = UrlParamsReplace(
  '/form/create-purchase-order-api'
);
export const SEND_PUSH_SMS = (id, recipientPhoneNumber) =>
  UrlParamsReplace(
    '/dashboard/order/send-push-sms-message/:id/:recipientPhoneNumber',
    {
      id,
      recipientPhoneNumber,
    }
  );

export const CREATE_RECEIVING_SLIP_API = UrlParamsReplace(
  '/form/create-receiving-slip-api'
);

//Customer DB Credentials
export const GET_CUSTOMER_DB_CREDENTIALS_BY_USER_ID = UrlParamsReplace(
  '/customerDBCredentials/userId'
);

//Payments
export const CREATE_PAYMENT = UrlParamsReplace('/payments');

export const GET_PAYMENTS_STATUS = (projectId, searchText, page, status) =>
  UrlParamsReplace(
    '/payments-status-details?searchText=:searchText&projectId=:projectId&page=:page&status=:status',
    {
      searchText,
      projectId,
      page,
      status,
    }
  );

export const GET_PAYMENT_DETAILS_BY_PO_ID = (poId) =>
  UrlParamsReplace('/payments/purchaseOrder/:poId', { poId });

export const GET_PAYMENT_DETAILS_BY_INVOICE_ID = (invoiceId) =>
  UrlParamsReplace('/payments/invoice/:invoiceId', { invoiceId });

export const EDIT_PAYMENT_DETAILS = (paymentId) =>
  UrlParamsReplace('/payments/:paymentId', { paymentId });

// Product Template
export const CREATE_PRODUCT_TEMPLATES = UrlParamsReplace(
  '/product_templates/create_product_template'
);
export const GET_ALL_PRODUCT_TEMPLATES = (businessAccountId, page) =>
  UrlParamsReplace('/product_templates/:businessAccountId/:page', {
    businessAccountId,
    page,
  });
export const GET_PRODUCT_TEMPLATE = (
  id,
  status,
  whatsappMainMenu,
  businessAccountId
) =>
  UrlParamsReplace(
    '/product_template?id=:id&status=:status&whatsappMainMenu=:whatsappMainMenu&businessAccountId=:businessAccountId',
    {
      id,
      status,
      whatsappMainMenu,
      businessAccountId,
    }
  );
export const GET_BUSINESS_ACTIVE_PRODUCT_TEMPLATE = (
  id,
  status,
  whatsappMainMenu,
  businessAccountId
) =>
  UrlParamsReplace(
    '/business_active_product_template?id=:id&status=:status&whatsappMainMenu=:whatsappMainMenu&businessAccountId=:businessAccountId',
    {
      id,
      status,
      whatsappMainMenu,
      businessAccountId,
    }
  );
export const UPDATE_PRODUCT_TEMPLATE = UrlParamsReplace(
  '/update-product-template'
);

export const UPDATE_PRODUCT_TEMPLATE_STATUS = (productTemplateId, status) =>
  UrlParamsReplace('/productTemplate/:productTemplateId/:status', {
    productTemplateId,
    status,
  });

//Flyer
export const CREATE_UPDATE_FLYER = UrlParamsReplace('/flyer/flyer-items');
export const CREATE_FLYER = UrlParamsReplace('/flyer/flyer-items');
export const UPDATE_FLYER = UrlParamsReplace('/flyer/flyer-items-update');

export const GET_ALL_FLYERS = (businessAccountId) =>
  UrlParamsReplace('/flyers/:businessAccountId', { businessAccountId });

export const GENERATE_PRESIGNED_META_URL = (folderName, storeName, fileName) =>
  UrlParamsReplace(
    '/generate-presigned-meta-url/:folderName/:storeName/:fileName',
    {
      folderName,
      storeName,
      fileName,
    }
  );

export const UPDATE_FLYER_STATUS = (flyerId, status) =>
  UrlParamsReplace('/flyer/:flyerId/:status', {
    flyerId,
    status,
  });

export const GET_ALL_CHANNELS = (businessAccountId) =>
  UrlParamsReplace('/channel_list', {
    businessAccountId,
  });
// Channel Whatsapp
export const CREATE_WHATSAPP_CHANNEL = UrlParamsReplace(
  '/channel-whatsapp/create-channel-whatsapp'
);

export const UPDATE_CATALOGUE = UrlParamsReplace(
  '/channel-whatsapp/update-catalogue'
);

export const CREATE_MPM_TEMPLATE = UrlParamsReplace(
  '/channel-whatsapp/create-mpm-template'
);

export const GET_CATALOGUE_DETAILS = (businessAccountId) =>
  UrlParamsReplace('/channel-whatsapp/catalogue/:businessAccountId', {
    businessAccountId,
  });

// Afto Chat Account
export const GET_CHAT_USER_SSO_LINK = (userId) =>
  UrlParamsReplace(`/chatAccount/User/chatUserSSOLink?userId=${userId}`);

// POS List
export const GET_ALL_POS_LIST = UrlParamsReplace('/pos_list');

export const UPDATE_BUSINESS_POS = UrlParamsReplace('/assign-pos-to-business');

//Industry
export const GET_ALL_INDUSTRY_LIST = UrlParamsReplace('/industries');

// library
export const GET_ALL_LIBRARY_TEMPLATES = (id) =>
  UrlParamsReplace('/library/templates/:id', {
    id,
  });
export const ADD_LIBRARY_TEMPLATES = UrlParamsReplace('/library/add-template');
export const FORK_LIBRARY_TEMPLATES = UrlParamsReplace('/library/fork');
export const GET_FORK_COUNT_LIBRARY_TEMPLATES = (templateId) =>
  UrlParamsReplace('/library/get-template-count/:templateId', {
    templateId,
  });

export const GET_LIBRARY_TEMPLATES = (templateId) =>
  UrlParamsReplace('/library/template/:templateId', {
    templateId,
  });
