import Avatar from 'atoms/Avatar';
import IcomoonIcon from 'atoms/IcomoonIcon';
import Logo from 'atoms/Logo';
import SearchBox from 'atoms/SearchBox';
import Text from 'atoms/Text';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { removeAuth } from 'services/identity.service';
import { getUserDetails } from 'services/user.service';
import { isValidUUID } from 'utils/helper';
import Hamburger from 'hamburger-react';
import PropTypes from 'prop-types';

const Navbar = ({ ToggleNav }) => {
  const [showLogout, setShowLogout] = useState(false);
  const router = useRouter();
  const { userId, businessAccountId } = router.query;
  const { data: session } = useSession();
  const [userDetails, setUserDetails] = useState({});
  const handleClickOutside = () => {
    setShowLogout(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    isValidUUID(userId) && fetchUserDetails();
  }, [userId]);

  const fetchUserDetails = async () => {
    try {
      const response = await getUserDetails(userId);
      setUserDetails(response.entity);
    } catch (error) {
      console.error(error);
    }
  };

  const logout = () => {
    if (isValidUUID(userId)) {
      window.close();
    } else {
      signOut();
      removeAuth();
    }
  };

  return (
    <div className="flex items-center justify-between px-6 gap-x-3 h-[50px] 2xl:h-[70px] border-b border-secondary-200">
      <div className="flex items-center mobile-margin-left">
        <div className="show-on-mobile mr-2">
          <Hamburger onToggle={() => ToggleNav()} />
        </div>

        <Logo
          src="/images/aftoLogoBlue.svg"
          alt="afto logo"
          className="w-[50px] h-[40px] 2xl:w-[68px] 2xl:h-[46px]"
        />
      </div>
      {isValidUUID(userId) && isValidUUID(businessAccountId) && (
        <div className="bg-darkGrey-300 py-2 px-3 rounded-3xl">
          <Text
            variant="bodySmall"
            textColor="text-primary-900"
            fontWeight="font-medium"
          >
            Logged in as Admin <br />
            Customer account:
            {`${userDetails?.firstName} ${userDetails?.lastName || ''}`}
          </Text>
        </div>
      )}
      <div className="flex gap-x-4 items-center">
        <SearchBox />
        <div className="hide-on-mobile">
          <IcomoonIcon
            icon="Settings"
            onClick={() => {
              isValidUUID(userId) && isValidUUID(businessAccountId)
                ? router.push(
                    `/settings?userId=${userId}&businessAccountId=${businessAccountId}`
                  )
                : router.push('/settings');
            }}
            className="w-[24px] h-[24px] xl:w-[30px] xl:h-[30px] 2xl:w-9 2xl:h-9 cursor-pointer fill-secondary-100 hover:fill-black-900 transition duration-300"
          />
        </div>
        <div className="hide-on-mobile">
          <div className="relative">
            <Avatar
              avatarSize="medium"
              bgColor="bg-blue-50"
              className="cursor-pointer"
              imgAlt="face"
              imgHeight="43"
              imgSrc={session?.user?.image || '/images/face5.svg'}
              imgWidth="43"
              shape="circle"
              size="medium"
              onClick={(e) => {
                e.stopPropagation();
                setShowLogout(!showLogout);
              }}
            />
            {showLogout && (
              <div
                className="absolute z-[62] right-0 top-full mt-1 w-[100px] xl:w-[130px] border border-secondary-200 py-1 xl:py-2 rounded bg-white cursor-pointer transition-all ease-out duration-300"
                onClick={logout}
              >
                <Text
                  variant="bodySmall"
                  textColor="text-black-800"
                  fontFamily=""
                  className="pl-3 py-1.5 xl:py-2 hover:bg-neutral-600"
                >
                  Logout
                </Text>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

Navbar.propTypes = {
  ToggleNav: PropTypes.func,
};
