import getLayout from '../../Layout/LayoutConfiguration';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { getCustomerDBCredentialsByUserId } from 'services/customer-db-credentials.service';

const updateSessionData = async (session, sessionUpdate) => {
  const responseCredentials = await getCustomerDBCredentialsByUserId();
  if (responseCredentials?.entity) {
    if (session) {
      if (session.user && session.user.isNewUser === false) {
        session.user.name = `${session.user.firstName} ${session.user.lastName}`;
        session.user.elasticIndexName = responseCredentials?.entity.indexName;
        sessionUpdate(session);
      }
    }
  }
};

const App = ({ Component, pageProps, router }) => {
  if (!['/login', '/welcome'].includes(router.pathname)) {
    const { data: session, update: sessionUpdate } = useSession();
    useEffect(() => {
      if (
        session &&
        session.user &&
        session.user.isNewUser === false &&
        !session.user.elasticIndexName
      ) {
        updateSessionData(session, sessionUpdate);
      }
    }, []);

    useEffect(() => {
      const now = new Date();
      const midnight = new Date(now);
      midnight.setHours(24, 0, 0, 0);
      const timeUntilMidnight = midnight - now;

      const timer = setTimeout(() => {
        window.location.reload();
      }, timeUntilMidnight);

      return () => clearTimeout(timer);
    }, []);
  }

  const layout = getLayout(router.pathname);
  return <>{layout({ children: <Component {...pageProps} /> })}</>;
};
export default App;
